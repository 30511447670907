<div class="container">
    <div class="d-flex justify-content-center" *ngIf="isLoading2">
        <app-loading></app-loading>
    </div>
    <div *ngIf="!jobsAvailable && !isLoading2">
        <h2>UH OH!</h2>
        <p> There are no jobs yet.</p>
    </div>
    <div *ngIf="jobsAvailable && !isLoading2">
        <div class="card" *ngFor="let job of jobs | paginate : jobsPagingConfig; let i = index">
            <div class="card-body">
                <h4 class="card-title">{{job.title}}</h4>
                <p class="card-text mb-2">{{job.description}}</p>
                <div class="row mb-3">
                    <div class="col-md-4">
                        <p class="card-text"><strong>Posted: </strong>{{job.created_on | date: 'mediumDate'}}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="card-text"><strong>Budget: </strong>{{(job.freelancer_amount).toFixed(2)}} USD</p>
                    </div>
                    <div class="col-md-4">
                        <p class="card-text"><strong>Deadline: </strong>{{job.deadline | date: 'mediumDate'}}</p>
                    </div>
                </div>
                <p class="card-text mb-2"><strong>Required Skills: </strong>{{job.skills}}</p>
                <button type="button" class="btn btn-info mb-3" routerLink="/login">LOG IN TO APPLY</button>
            </div>
        </div>
        <div class="d-flex justify-content-center paginator">
            <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onJobsDataChange($event)">
            </pagination-controls>
        </div>
    </div>
</div>